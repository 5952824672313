import { ENV } from "../env/env";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LendInfo } from "../components/LendInfo";

const LendInfoPage = () => {
  const [lend, setLend] = useState();
  const [partners, setPartners] = useState([]);
  const { containerId } = useParams();

  const fetchPartners = async () => {
    const response = await fetch(`${ENV.ENDPOINT}/partners?limit=100`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    setPartners(data.data);
  };

  const fetchLend = async () => {
    const response = await fetch(`${ENV.ENDPOINT}/lends/public/${containerId}`);
    const data = await response.json();
    if (response.ok) {
      setLend(data.data);
    }
  };

  useEffect(() => {
    fetchPartners();
    fetchLend();
  }, []);

  return <LendInfo lend={lend} partners={partners} />;
};

export default LendInfoPage;
