import { View } from "react-native-web";
import { theme } from "../styles/theme";
import StyledText from "../components/StyledText";
import { t } from "i18next";
import fonts from "../styles/fonts";
import TickIcon from "../assets/tick.svg";
import PoweredIcon from "../assets/poweredByBumerang.svg";
import { Trans } from "react-i18next";
import { useSearchParams } from "react-router-dom";

const SquareOauthPage = () => {
  const [searchParams] = useSearchParams();
  const success = searchParams.get("success") === "true";
  /*   useEffect(() => {
    const loadParams = async () => {
      const name = await AsyncStorage.getItem("name");
      const email = await AsyncStorage.getItem("email");
      const tab = await AsyncStorage.getItem("tab");
      email !== "undefined" && setEmail(email);
      name !== "undefined" && setText(name);
      tab !== "undefined" && tab === "register"
        ? setLoginTab(false)
        : setLoginTab(true);
    };
    loadParams();
  }, []); */

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: theme.colors.white,
        justifyContent: "space-around",
        alignItems: "center",
      }}
    >
      {success && <img src={TickIcon} alt="tick icon"></img>}
      <View
        style={{
          alignItems: "center",
          gap: theme.spacing.m,
          paddingHorizontal: theme.spacing.xl,
        }}
      >
        <StyledText
          size={theme.sizes.xxl}
          style={fonts.ginto}
          color={theme.colors.black}
          align="center"
        >
          {success ? t("oAuthAcceptedTitle") : t("oAuthErrorTitle")}
        </StyledText>
        <StyledText
          mainText
          size={25}
          color={theme.colors.black}
          align="center"
          style={{ flexWrap: "wrap" }}
        >
          <Trans i18nKey={success ? "oAuthAcceptedText" : "oAuthErrorText"} />
        </StyledText>
      </View>
      <img src={PoweredIcon} alt="powered by bumerang logo"></img>
    </View>
  );
};

export default SquareOauthPage;
