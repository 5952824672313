import { theme } from "./theme";

const fonts = {
  brand: {
    fontFamily: "Migha-Black",
    fontSize: theme.sizes.xxl,
    color: theme.colors.primary,
    lineHeight: 32,
  },
  superBig: {
    fontSize: theme.sizes.xxxl,
    fontFamily: "Raleway",
    fontWeight: 700,
    color: theme.colors.white,
    paddingBottom: 8,
    paddingLeft: theme.spacing.l,
    paddingRight: theme.spacing.l,
    lineHeight: 40,
  },
  subtitle: {
    fontFamily: "Raleway",
    fontWeight: 700,
    color: theme.colors.primary,
    fontSize: theme.sizes.l,
  },
  mainText: {
    fontSize: theme.sizes.m,
    fontFamily: "Raleway",
    fontWeight: 500,
    color: theme.colors.primary,
  },
  screenTitleRegular: {
    fontSize: theme.sizes.xl,
    fontFamily: "Raleway",
    fontWeight: 500,
    color: theme.colors.primary,
  },
  screenTitleBold: {
    fontSize: theme.sizes.xl,
    fontFamily: "Raleway",
    fontWeight: 700,
    color: theme.colors.primary,
  },
  mainTextLink: {
    fontFamily: "Raleway",
    fontWeight: 700,
    color: theme.colors.primary,
    textDecorationLine: "underline",
    fontSize: theme.sizes.m,
  },
  buttonText: {
    fontFamily: "Raleway",
    fontWeight: 500,
    fontSize: 18,
    letterSpacing: 0.7,
  },
  tabLabel: {
    fontFamily: "Raleway-Medium",
    fontSize: theme.sizes.xs,
    color: theme.colors.primaryDisabled,
  },
  tabLabelActive: {
    fontFamily: "Raleway-Bold",
    fontSize: theme.sizes.xs,
    color: theme.colors.primary,
  },
  toastText: {
    fontFamily: "Raleway-Medium",
    color: theme.colors.white,
    fontSize: theme.sizes.m,
  },
  ginto: {
    fontFamily: "GintoRounded",
  },
};
export default fonts;
