import {
  ActivityIndicator,
  Linking,
  Pressable,
  View,
  Clipboard,
  StyleSheet,
} from "react-native-web";
import ContainerItem from "./ContainerItem";
import StyledText from "./StyledText";
import { t } from "i18next";
import Separator from "./Separator";
import { theme } from "../styles/theme";
import { ENV } from "../env/env";
import { toast } from "react-toastify";
import { getContainerInfo } from "../utils/getContainerInfo";
import { getRemainingDays } from "../utils/getRemainingDays";
import { getMapIcon } from "../utils/getMapIcon";
import Card from "./Card";
import ContactIcon from "../assets/contact_green_background.svg";
import MapView from "react-native-web-maps";
import { useEffect, useState } from "react";

export const LendInfo = ({ lend, partners }) => {
  const [position, setPosition] = useState();

  const getCurrentPosition = () => {
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        setPosition(pos);
      },
      (positionError) =>
        console.log("GetCurrentPosition Error", JSON.stringify(positionError)),
      { enableHighAccuracy: true }
    );
  };

  useEffect(() => {
    getCurrentPosition();
  }, []);

  return (
    <View
      style={{
        paddingLeft: 31,
        paddingRight: 31,
        width: "100%",
      }}
    >
      <View
        style={{
          width: "100%",
          height: 180,
          alignItems: "center",
          justifyContent: "center",
          gap: theme.spacing.xs,
          borderRadius: 10,
          overflow: "hidden",
        }}
      >
        {position ? (
          <MapView
            style={{
              width: "100%",
              height: "100%",
              borderRadius: 10,
              zIndex: 0,
            }}
            region={{
              latitude: position?.coords.latitude,
              longitude: position?.coords.longitude,
            }}
          >
            {
              <MapView.Marker
                title="Your position"
                coordinate={{
                  latitude: position?.coords.latitude,
                  longitude: position?.coords.longitude,
                }}
              ></MapView.Marker>
            }
            {partners.map((partner) => (
              <MapView.Marker
                key={partner.id}
                title={partner.name}
                coordinate={{ latitude: partner.lat, longitude: partner.lng }}
                icon={getMapIcon(partner)}
              ></MapView.Marker>
            ))}
          </MapView>
        ) : (
          <ActivityIndicator />
        )}
      </View>
      <Separator isOption margin={theme.spacing.s}></Separator>
      {lend && lend.status === "active" ? (
        <>
          <Pressable
            style={[styles.chatButtonTopRight, styles.chatButton]}
            testID="contactButton"
            onPress={() =>
              Linking.openURL(
                `https://go.crisp.chat/chat/embed/?website_id=${ENV.CRISP_ID}`
              )
            }
          >
            <img src={ContactIcon} alt="contact support button"></img>
          </Pressable>
          <View style={styles.topText}>
            <StyledText>
              {t("daysLeft", {
                days: getRemainingDays(lend.returnDeadlineDay),
              })}
            </StyledText>
            <StyledText>
              {t("dateLimit", {
                day: new Date(lend.returnDeadlineDay).getDate(),
                month: new Date(lend.returnDeadlineDay).getMonth() + 1,
              })}
            </StyledText>
          </View>
        </>
      ) : (
        lend &&
        lend.status === "collected" && (
          <>
            <View style={styles.topText}>
              <StyledText
              // size={16}

              // style={{
              //   fontWeight: 600,
              //   lineHeight: '18.78px',
              //   textAlign: 'center',
              //   paddingTop: theme.spacing.xl,
              //   paddingBottom: theme.spacing.m
              // }}
              >
                {t("containerSuccesfullyReturned")}
              </StyledText>
            </View>
            <Card>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  gap: theme.spacing.xs,
                }}
              >
                <img
                  src={getContainerInfo(lend.productTypeId).icon}
                  alt="bowl returning"
                ></img>
                <StyledText>{`${getContainerInfo(lend.productTypeId).name} - ${
                  lend.containerName
                }`}</StyledText>
              </View>
              <StyledText>
                {t("returnedAt", {
                  collectorName: lend.collectorName,
                  day: new Date(lend.updatedAt).getDate(),
                  month: new Date(lend.updatedAt).getMonth() + 1,
                })}
              </StyledText>

              <Pressable
                onPress={() => {
                  Clipboard.setString(lend.lendId);
                  toast.success(t("copiedToClipboard"));
                }}
              >
                <StyledText>{`Id - ${lend.lendId}`}</StyledText>
              </Pressable>
            </Card>
            <Pressable
              style={[styles.chatButton, { marginTop: theme.spacing.xl }]}
              testID="contactButton"
              onPress={() =>
                Linking.openURL(
                  `https://go.crisp.chat/chat/embed/?website_id=${ENV.CRISP_ID}`
                )
              }
            >
              <img src={ContactIcon} alt="contact support button"></img>
            </Pressable>
          </>
        )
      )}

      {lend ? (
        lend?.status === "active" && (
          <>
            <Card>
              <StyledText>
                {t("lentAt", { partnerName: lend.partnerName })}
              </StyledText>
              <StyledText>
                {t("dateLimit", {
                  day: new Date(lend.returnDeadlineDay).getDate(),
                  month: new Date(lend.returnDeadlineDay).getMonth() + 1,
                })}
              </StyledText>
              <Pressable
                onPress={() => {
                  Clipboard.setString(lend.lendId);
                  toast.success(t("copiedToClipboard"));
                }}
              >
                <StyledText>{`Id - ${lend.lendId}`}</StyledText>
              </Pressable>
            </Card>
            <View style={styles.containerSection}>
              <ContainerItem
                productTypeId={lend.productTypeId}
                containerName={lend.containerName}
              />
            </View>
          </>
        )
      ) : (
        <StyledText screenTitleBold>{t("lendNotFoundError")}</StyledText>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  main: {
    flex: 1,
    backgroundColor: theme.colors.backgroundGreen,
    width: "100%",
    padding: theme.spacing.m,
    gap: theme.spacing.m,
  },
  chatButton: {
    backgroundColor: theme.colors.primary,
    width: 50,
    // marginTop: theme.spacing.xxl,
    marginRight: "auto",
    marginLeft: "auto",
    marginBottom: theme.spacing.l,
    padding: theme.spacing.xs,
    borderRadius: 50,
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 10px 15px -5px, rgba(0, 0, 0, 0.05) 0px 5px 6px -2px",
  },
  chatButtonTopRight: {
    position: "absolute",
    right: theme.spacing.xs,
    top: theme.spacing.xs,
    margin: "auto!important",
  },
  topText: {
    alignItems: "center",
    gap: theme.spacing.xxs,
    marginTop: theme.spacing.xl,
    marginBottom: theme.spacing.m,
  },
  containerSection: {
    width: "100%",
    gap: theme.spacing.xs,
    paddingBottom: theme.spacing.l,
  },
});
