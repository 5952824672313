import React from "react";
import {
  Linking,
  Pressable,
  ScrollView,
  StyleSheet,
  View,
  useWindowDimensions,
} from "react-native-web";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../assets/splash-logo.svg";
import { FaArrowLeft } from "react-icons/fa";
import WebIcon from "../assets/web-icon.svg";
import InstagramIcon from "../assets/ig_icon.svg";
import LinkedInIcon from "../assets/linkedin_icon.svg";
import YoutubeIcon from "../assets/youtube_icon.svg";
import { theme } from "../styles/theme";
const Layout = ({ children }) => {
  const navigate = useNavigate();
  const { height } = useWindowDimensions();
  const location = useLocation();
  const isPaymentMethodPage = location.pathname === "/paymentMethod";
  return location.pathname !== "/squareOauth" ? (
    <View style={{ height: height }}>
      {isPaymentMethodPage && (
        <Pressable
          onPress={() => navigate(-1)}
          style={{
            position: "absolute",
            top: 35,
            left: 10,
            zIndex: 1,
          }}
        >
          <FaArrowLeft size={24} color="#fff" />
        </Pressable>
      )}
      <View style={styles.section}>
        <Pressable onPress={() => navigate("", { replace: true })}>
          <img src={logo} width={100} alt="bumerang-logo" />
        </Pressable>
      </View>
      <ScrollView contentContainerStyle={styles.container}>
        {children}
      </ScrollView>
      <View style={styles.footer}>
        <Pressable onPress={() => Linking.openURL("https://youbumerang.com/")}>
          <img src={WebIcon} style={styles.icon} alt="instagram-icon" />
        </Pressable>
        <Pressable
          onPress={() =>
            Linking.openURL("http://www.instagram.com/youbumerang")
          }
        >
          <img src={InstagramIcon} style={styles.icon} alt="instagram-icon" />
        </Pressable>
        <Pressable
          onPress={() =>
            Linking.openURL("https://www.linkedin.com/company/youbumerang")
          }
        >
          <img src={LinkedInIcon} style={styles.icon} alt="linkedin-icon" />
        </Pressable>
        <Pressable
          onPress={() =>
            Linking.openURL(
              "https://www.youtube.com/channel/UClY2I7ktF201fsQ5pnPjrdg"
            )
          }
        >
          <img src={YoutubeIcon} style={styles.icon} alt="youtube-icon" />
        </Pressable>
      </View>
    </View>
  ) : (
    <ScrollView>
      <View style={{ height: height }}>{children}</View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    backgroundColor: theme.colors.backgroundGreen,
  },
  section: {
    backgroundColor: theme.colors.primary,
    paddingTop: 30,
    paddingBottom: 30,
    alignItems: "center",
    justifyContent: "center",
  },
  footer: {
    backgroundColor: theme.colors.primary,
    paddingTop: 30,
    paddingBottom: 30,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    gap: theme.spacing.xxs,
  },
  icon: { color: theme.colors.white, width: 20 },
});
export default Layout;
